<template>
	<div>
		<!-- <BlockUI :message="defaultMsg" :html="html" v-if="isLoading"></BlockUI> -->
		<CCard class="p-2">
			<form ref="form" @submit.prevent="submit">
				<CCardHeader>
					<CRow>
						<CCol lg="6">
							<h5 v-if="!editMode">Create Client Billing</h5>
							<h5 v-else>Update Client Billing / {{ dataParams.client_billing_no }}</h5>
						</CCol>
						<CCol lg="6">
							<div style="text-align: right">
								<CButton size="sm" class="btn btn-ghost-dark" @click="backToTable()"><i
										class="fa fa-close"></i> Cancel</CButton> &nbsp;
								<CButton size="sm" color="info" id="btn_submit" type="submit"><i class="fa fa-save"></i>
									Save</CButton>
							</div>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody>
					<CCard class="p-2">
						<CRow class="ml-3 mr-3">
							<CCol lg="12">
								<strong style="font-size:13px" class="text-dark">Billing Information</strong>
							</CCol>
						</CRow>
						<br />
						<CCol lg="4">
							<h4 class="text-success" style="text-align: right;" v-if="isLoading">
								Uploading...({{ uploadPercentage }}%)</h4>
						</CCol>
						<CRow class="ml-3 mr-3">
							<CCol lg="3">
								<div class="form-group">
									<label>Customer <span class="text-danger">*</span></label>
									<v-select label="customer_name" :options="customerList.data" :reduce="item => item.id"
										v-model="dataParams.customer_id" placeholder="-Select-">
										<template #search="{ attributes, events }">
											<input class="vs__search" :required="!dataParams.customer_id"
												v-bind="attributes" v-on="events" />
										</template>
									</v-select>
								</div>
							</CCol>
							<CCol lg="3">
								<CInput label="Ref. No." v-model="dataParams.ref_no" />
							</CCol>
							<CCol lg="3">
								<div class="form-group">
									<label>Tax Type <span class="text-danger">*</span></label>
									<v-select label="setting_name" :options="[
										{
											setting_name: 'VAT',
											id: 'vat'
										},
										{
											setting_name: 'Non VAT',
											id: 'nonvat'
										}
									]" :reduce="item => item.id" v-model="dataParams.tax_type" placeholder="-Select-" @input="taxTypeUpdate">
										<template #search="{ attributes, events }">
											<input class="vs__search" :required="!dataParams.tax_type" v-bind="attributes"
												v-on="events" />
										</template>
									</v-select>
								</div>
							</CCol>
							<CCol lg="3">
								<div class="form-group">
									<label>Status <span class="text-danger">*</span></label>
									<i class="fa fa-question-circle ml-2" title="You cannot edit the waybill/bookings if it's already billed in the client billing." ></i>
									<v-select label="setting_name" :options="[
										{
											setting_name: 'Cancelled',
											id: 'cancelled'
										},
										{
											setting_name: 'Billed',
											id: 'billed'
										},
										{
											setting_name: 'Unbilled',
											id: 'unbilled'
										}
									]" @input="statusChanged($event)" :reduce="item => item.id" v-model="dataParams.status"
										placeholder="-Select-">
										<template #search="{ attributes, events }">
											<input class="vs__search" :required="!dataParams.status" v-bind="attributes"
												v-on="events" />
										</template>
									</v-select>
								</div>
							</CCol>
							
						</CRow>
						<CRow class="ml-3 mr-3" v-if="is_marvel()">
							<CCol lg="3">
								<div class="form-group">
									<label>Term <span class="text-danger">*</span></label>
									<v-select label="label" :options="term_option" v-model="dataParams.term" :reduce="item => item.value" @input="changeTerm" placeholder="-Select-">
                                        <template #search="{ attributes, events }">
                                            <input class="vs__search" :required="!dataParams.term" v-bind="attributes" v-on="events"/>
                                        </template>
                                    </v-select>
								</div>
							</CCol>
							<!-- <CCol lg="3" v-if="dataParams.term != 'custom'">
								<div class="form-group">
									<CInput v-model="dataParams.term_due_date" readonly label="Due Date"/>
								</div>
							</CCol> -->
							<CCol lg="3">
								<div class="form-group">
									<label for="exampleInputEmail1">Due Date<span class="text-danger"> *</span></label>
									<Datepicker input-class="form-control bg-white" v-model="dataParams.term_due_date" @input="changeTermDueDate"
										:typeable="true" required>
									</Datepicker>
									<!-- <Datetime format="MM-DD-YYYY" v-model="dataParams.delivery_date_from" required></Datetime> -->
								</div>
							</CCol>
						</CRow>
						<CRow class="ml-3 mr-3">
							<CCol lg="12">
								<strong style="font-size:13px" class="text-dark">Covered Trips</strong>
							</CCol>
						</CRow>
						<br>
						<CRow class="ml-3 mr-3">
							<CCol lg="3">
								<div class="form-group">
									<label>Depot </label>
									<v-select :disabled="depot_id != null" label="setting_name" :options="depotList.data"
										:reduce="item => item.id" v-model="dataParams.depot_id" placeholder="-Select-">
										<!-- <template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.depot_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template> -->
									</v-select>
								</div>
							</CCol>


							<CCol lg="3">
								<div class="form-group">
									<label>Origin</label>
									<v-select label="setting_name" :options="originList.data" :reduce="item => item.id"
										v-model="dataParams.origin_id" placeholder="-Select-">

									</v-select>
								</div>
							</CCol>
							<CCol lg="3">
								<div class="form-group">
									<label>Commodity Type</label>
									<v-select label="setting_name" :options="commodityTypeList.data"
										:reduce="item => item.id" v-model="dataParams.commodity_id" placeholder="-Select-">

									</v-select>
								</div>
							</CCol>
						</CRow>
						<CRow class="ml-3 mr-3">
							<CCol lg="3">
								<div class="form-group">
									<label for="exampleInputEmail1">Delivery Date From <span
											class="text-danger">*</span></label>
									<Datepicker input-class="form-control bg-white" v-model="dataParams.delivery_date_from"
										:typeable="true" required>
									</Datepicker>
									<!-- <Datetime format="MM-DD-YYYY" v-model="dataParams.delivery_date_from" required></Datetime> -->
								</div>
							</CCol>
							<CCol lg="3">
								<div class="form-group">
									<label for="exampleInputEmail1">Delivery Date To <span
											class="text-danger">*</span></label>
									<Datepicker input-class="form-control bg-white" v-model="dataParams.delivery_date_to"
										:typeable="true" required>
									</Datepicker>

									<!-- <Datetime format="MM-DD-YYYY" v-model="dataParams.delivery_date_to" required></Datetime> -->
								</div>
							</CCol>
						</CRow>
						<CRow class="ml-3 mr-3">
							<CCol lg="6">

							</CCol>
							<CCol lg="6">
								<div style="text-align: right">
									<CButton size="sm" class="px-3" color="secondary" @click="clearSearch">Clear</CButton>
									&nbsp;
									<CButton size="sm" color="info" @click="validate"><i class="icon-magnifier"></i> Search
									</CButton>
								</div>
							</CCol>
						</CRow>
						<br />
					</CCard>
					<br>
					<CRow class="mb-0">
						<CCol lg="12">
							<div class="table-responsive-sm table-responsive-md">
								<CDataTable 
								:items="bookingList.data"
								:fields="tableFields"
								hover
								striped
								small
								fixed
								pagination
								:itemsPerPage="20"
								:loading="searching">
								<template #id="{ item }">
									<td style="padding: 5px; width: 30px">
										<input :checked="setChecked(item.id)" type="checkbox" @change="event => checkChange(event, item)" class="form-control" />
									</td>
								</template>
								<template #status="{ item }">
									<td style="padding: 5px; width: 100px">
										{{ item.status }}
									</td>
								</template>
							</CDataTable>
							<!-- <CPagination :active-page.sync="currentPage" :pages="Math.ceil(bookingList.data.length / 20)"
								:activePage="currentPage" @update:activePage="updatePage" /> -->
							</div>
							
						</CCol>
					</CRow>
					<CRow>
						<CCol lg="8" class="text-right"></CCol>
						<CCol lg="4" class="text-left">
							<ul class="list-group list-group-flush">
								<!-- <li class="list-group-item d-flex justify-content-between align-items-center py-1" style="font-size:12px">
									Client Rate
									<strong>{{dataParams.clientrate_total | number_format(null, 2) }}</strong>
								</li>
								<li v-if="is_trucking()" class="list-group-item d-flex justify-content-between align-items-center py-1" style="font-size:12px">
									Toll Fee
									<strong>{{dataParams.tollfee_total | number_format(null, 2) }}</strong>
								</li>
								<li v-if="is_trucking()" class="list-group-item d-flex justify-content-between align-items-center py-1" style="font-size:12px">
									Extra Drop
									<strong>{{dataParams.extradrop_total | number_format(null, 2) }}</strong>
								</li>
								<li v-if="is_trucking()" class="list-group-item d-flex justify-content-between align-items-center py-1" >
									<strong class="font-w700">Total</strong>
									<strong>{{dataParams.sub_total | number_format(null, 2) }}</strong>
								</li>
								<li class="list-group-item d-flex justify-content-between align-items-center py-1" style="font-size:12px; font-style:italic">
									Tax (12%)
									<i>{{ dataParams.tax_type == 'vat' ? dataParams.sub_total * 0.12 : 0 | number_format(null, 2) }}</i>
								</li>
								<li v-if="is_trucking()" class="list-group-item d-flex justify-content-between align-items-center py-1" style="font-size:12px">
									Other Expenses
									<strong>{{dataParams.other_payments | number_format(null, 2) }}</strong>
								</li> -->
								<li class="list-group-item"
									style="font-weight:700; font-size:20px">
									<span class="float-right">TOTAL 
									<strong class="ml-5">{{ computedTotal | number_format('currency') }}</strong>
								</span>
									<!-- <strong>{{ dataParams.total_amount | number_format('currency') }}</strong> -->
								</li>
								
							</ul>
						</CCol>
						<!-- <CCol lg="9"></CCol>
						<CCol lg="3 text-right">
							<strong>Overall Total Amount: {{dataParams.total_amount.toFixed(2)}} </strong>
						</CCol> -->
					</CRow>
				</CCardBody>
			</form>
		</CCard>
	</div>
</template>
<script>
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import '../../style.css';
import Datetime from 'vuejs-datetimepicker';
import moment from 'moment';

export default {
	async mounted() {
		this.getCustomer();
		this.getCommodityType();
		this.getOrigin();
		this.getDepot();

		if (this.$route.params.id != undefined) {
			this.editMode = true;
		}
		if (this.$route.params.id) {
			await this.getData();
		}
	},
	data() {
		return {
			depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id,
			company_type: JSON.parse(window.localStorage.getItem("user_data")).company.company_type.includes("Trucking") ? "trucking" : JSON.parse(window.localStorage.getItem("user_data")).company.company_type,
			currentPage: 1,
			formModal: false,
			showViewModal: false,
			dataList: [],
			dataListInactive: [],
			depotName: "",
			dataParams: {
				customer_id: "",
				origin_id: "",
				commodity_id: "",
				ref_no: "",
				status: "",
				tax_type: "",
				delivery_date_from: "",
				delivery_date_to: "",
				total_amount: 0.00,
				overall_total: 0.00,
				depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id != null ? JSON.parse(window.localStorage.getItem('user_data')).depot_id : "",
				// date_billed: "",
				term: "",
				term_due_date:"",
			},
			editMode: false,
			selectedRow: {},
			vendorList: {
				data: []
			},
			vehicleTypeList: {
				data: []
			},
			makerModelList: {
				data: []
			},
			isLoading: false,
			uploadPercentage: 0,
			config,


			customerList: {
				data: []
			},
			bookingTypeList: {
				data: []
			},
			commodityTypeList: {
				data: []
			},
			depotList: {
				data: []
			},
			originList: {
				data: []
			},
			destinationList: {
				data: []
			},
			vehicleList: {
				data: []
			},
			driverList: {
				data: []
			},
			//use by monocrete
			fields: [
				
				{
					key: 'booking_no',
					label: 'Booking No.'
				},
				// {
				// 	key: 'customer_id_label', 
				// 	label: 'Customer'
				// },
				// {
				// 	key: 'asset_type', 
				// 	label: 'Asset Type'
				// }, 
				{
					key: 'vehicle_type_id_label',
					label: 'Trucker'
				},
				{
					key: 'delivery_date_label',
					label: 'Delivery Date'
				},
				// {
				// 	key: 'booking_type_id_label', 
				// 	label: 'Type'
				// },
				{
					key: 'commodity_id_label',
					label: 'Commodity'
				},
				// {
				// 	key: 'asset_no', 
				// 	label: 'Plate No.'
				// }, 
				{
					key: 'depot_id_label',
					label: 'Depot'
				},

				{
					key: 'origin_id_label',
					label: 'Origin'
				},
				// {
				// 	key: 'destination_id_label', 
				// 	label: 'Destination'
				// }, 
				{
					key: 'no_of_trips',
					label: 'No. of trips'
				},
				{
					key: 'client_rate',
					label: 'Trip Rates'
				},
				{
					key: 'total_amount',
					label: 'Total Rate'
				},
				{
					key: 'overall_total',
					label: 'Total Amount'
				}
			],
			// use by roadwise
			fields_trucking: [
				
				{
					key: 'booking_no',
					label: 'Booking No.'
				},
				// {
				// 	key: 'customer_id_label', 
				// 	label: 'Customer'
				// },
				// {
				// 	key: 'asset_type', 
				// 	label: 'Asset Type'
				// }, 
				{
					key: 'vehicle_type_id_label',
					label: 'Trucker'
				},
				{
					key: 'delivery_date_label',
					label: 'Delivery Date'
				},
				// {
				// 	key: 'booking_type_id_label', 
				// 	label: 'Type'
				// },
				{
					key: 'commodity_id_label',
					label: 'Commodity'
				},
				// {
				// 	key: 'asset_no', 
				// 	label: 'Plate No.'
				// }, 
				{
					key: 'depot_id_label',
					label: 'Depot'
				},
				{
					key: 'origin_id_label',
					label: 'Origin'
				},
				// {
				// 	key: 'destination_id_label', 
				// 	label: 'Destination'
				// },
				{
					key: 'total_amount',
					label: 'Client Rate'
				},
				{
					key: 'toll_fees',
					label: 'Toll Fees'
				},
				{
					key: 'extra_drop',
					label: 'Extra Drop'
				},
				{
					key: 'extra_helper',
					label: 'Extra Helper'
				},
				{
					key: 'other_fees',
					label: 'Other Fees'
				},
				// {
				// 	key: 'no_of_trips', 
				// 	label: 'No. of trips'
				// }, 
				// {
				// 	key: 'client_rate', 
				// 	label: 'Trip Rates'
				// },

				{
					key: 'overall_total',
					label: 'Total Amount'
				}
			],
			fields_marvel:  [
				{
					key: 'id', 
					label: ''
					
				},
				{
					key: 'booking_no', 
					label: 'Booking #'
					
				},
				
				{
					key: 'vehicle_type_id_label', 
					label: 'Trucker'
				}, 
				{
					key: 'delivery_date_label', 
					label: 'Delivery Date'
				}, 
				
				{
					key: 'commodity_id_label', 
					label: 'Commodity'
				},
				
				{
					key: 'depot_id_label', 
					label: 'Depot'
				}, 
				
				{
					key: 'origin_id_label', 
					label: 'Origin'
				}, 
				
				{
					key: 'total_amount', 
					label: 'Client Rate'
				},
				{
					key: 'toll_fees', 
					label: 'Toll Fees'
				}, 
				
				{
					key: 'overall_total', 
					label: 'Total Amount'
				}
			],
			displayedBooking: [],
			bookingList: {
				data: [],
				total: 0
			},
			disableSubmit: false,
			searching: false,
			depotList: {
				data: []
			},
			term_option:[
				{
					value: 30,
					label: '30 Days'
				},
				{
					value: 45,
					label: '45 Days'
				},
				{
					value: 60,
					label: '60 Days'
				},
				{
					value: 'custom',
					label: 'Custom'
				},
			],
			excluded_bookings: []
		}
	},
	computed: {
		computedTotal() {
			if(this.bookingList?.data.length)
				return this.processTotalAmount(this.bookingList.data)
			return 0;
		},
		setChecked() {
			return id => {
				let found = this.excluded_bookings.some(d => d == id);
				return !found;
			}
		},
		tableFields() {
				const company_type = JSON.parse(window.localStorage.getItem("user_data"))?.company?.company_type ?? null;
				switch (company_type?.toLowerCase()) {
					case 'trucking with agency':
						return this.fields_trucking	;
					case 'trucking with no agency':
						return this.fields_marvel;			
					default:
						return this.fields
				}
			
		}
		
	},
	name: 'Tables',
	components: { Search, Datepicker, vSelect, Datetime },
	methods: {
		checkChange(ev, item) {
			const checked = ev.target.checked;
			if(checked) {
				const index = this.excluded_bookings.indexOf(item.id);
				this.excluded_bookings.splice(index, 1);
			}
			else {
				if(this.excluded_bookings.indexOf(item.id) < 0) {
					this.excluded_bookings.push(item.id)
				}
				
			}
		},
		statusChanged(ev) {
			console.log(ev)
			let status = this.dataParams.status;
			this.$showLoading(true)
			setTimeout(() => {
				this.$showLoading(false)
				if (ev && ev.toLowerCase() == 'unbilled' && this.dataParams.xero_invoice_id) {
					Swal.fire({
						title: 'Reminder: ',
						text: "Invoice was already generated for this billing. Changing status to unbilled will not remove the Xero invoice.",
						icon: 'warning',
						showCancelButton: false,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Ok',
						reverseButtons: true,
					}).then((result) => {
						if (!result.isConfirmed) {
							this.dataParams.status = status;
						}
					})
				}
			}, 500);

		},
		updatePage(data) {
			this.currentPage = data;
			this.displayedBooking = [...this.bookingList.data];
			if (this.currentPage > 1) {
				this.displayedBooking.splice(0, ((this.currentPage - 1) * 20));
				this.displayedBooking.splice(((this.currentPage - 1) * 20), this.bookingList.data.length)
			}
			else {
				this.displayedBooking.splice(20, this.bookingList.data.length)
			}
		},
		backToTable() {
			this.$router.push('/main/client_billing')
		},
		submit() {
			// console.log(this.dataParams, this.disableSubmit)
			if (this.disableSubmit) {
				this.getBookings(1);
				return;
			}
			//  	if(this.bookingList.data.length == 0){
			//  		Swal.fire({
			// 	title: 'Ooooppss!',
			// 	text: "No bookings found!",
			// 	icon: 'warning', 
			// })
			// return;
			//  	}
			if (this.dataParams.origin_id == "") {
				this.dataParams.origin_id = null;
			}
			if (this.dataParams.commodity_id == "") {
				this.dataParams.commodity_id = null;
			}
			if (!this.dataParams.depot_id) {
				this.dataParams.depot_id = null
			}
			if (!this.dataParams.term) {
				this.dataParams.term = null
				this.dataParams.term_due_date = null
			}
			// console.log(this.dataParams)


			var ax = {};

			let bookings = this.bookingList.data.filter((val) => {
				return this.excluded_bookings.indexOf(val.id) < 0;
			})

			this.dataParams.bookings = bookings.map((value, index) => { return { id: value.id } });
			this.dataParams.delivery_date_from = moment(this.dataParams.delivery_date_from).startOf('day').unix();
			this.dataParams.delivery_date_to = moment(this.dataParams.delivery_date_to).startOf('day').unix() + (86399);
			this.dataParams.term_due_date = this.dataParams.term_due_date ? moment(this.dataParams.term_due_date).format('YYYY-MM-DD') : null;

			if (this.editMode) {
				delete this.dataParams.booking;
				ax = axios.put(config.api_path + "/client_billing/" + this.$route.params.id, this.dataParams)
			}
			else {
				ax = axios.post(config.api_path + "/client_billing", this.dataParams)
			}
			this.$showLoading(true);
			ax.then(response => {
				this.$showLoading(false);
				var text = 'Client billing successfully added!';
				if (this.editMode) {
					text = 'Client billing successfully updated!';
				}
				this.formModal = false
				this.editMode = false;
				this.dataParams = {
					customer_id: "",
					origin_id: "",
					commodity_id: "",
					ref_no: "",
					status: "",
					// tax_type: "",
					delivery_date_from: "",
					delivery_date_to: "",
					total_amount: "",
					// date_billed: "",
					bookings: [],
					depot_id: ""
				}
				Swal.fire({
					title: 'Success!',
					text,
					icon: 'success',
				})
					.then((result) => {
						this.bookingList.data = [];
						this.$router.push('/main/view_client_billing/' + response.data.data.id)
					})
			})
				.catch(err => {
					this.$showLoading(false);
				})
		},
		taxTypeUpdate() {
			// this.getBookings()
		},
		getCustomer() {
			axios.get(config.api_path + '/reference/customer-list', {
				params: {
					show_all: true
				}
			})
				.then(response => {
					this.customerList = response.data;
				})
		},
		getCommodityType() {
			axios.get(config.api_path + '/reference/settings-list', {
				params: {
					setting_type: 'commodity_type'
				}
			})
				.then(response => {
					this.commodityTypeList = response.data;
				})
		},
		getOrigin() {
			axios.get(config.api_path + '/reference/settings-list', {
				params: {
					setting_type: 'origin'
				}
			})
				.then(response => {
					this.originList = response.data;
				})
		},
		validate() {
			this.disableSubmit = true;
			document.getElementById('btn_submit').click();
		},

		getDepot() {
			axios.get(config.api_path + '/reference/settings-list', {
				params: {
					setting_type: 'depot'
				}
			})
				.then(response => {
					this.depotList = response.data;
				})
		},
		processTotalAmount(booking) {
			let total_amount = 0;
			let data = booking.filter(b => {
				return !this.excluded_bookings.some(eb => eb == b.id);
			})
			for (var i = 0; i < data.length; i++) {

				data[i].toll_fees = data[i].toll_fee ? data[i].toll_fee : "0.00";
				data[i].extra_drop = data[i].extra_drop ? data[i].extra_drop : "0.00";
				data[i].extra_helper = data[i].extra_helper ? data[i].extra_helper : "0.00";
				data[i].other_fees = data[i].other_expenses ? data[i].other_expenses : "0.00";

				if(this.is_roadwise()){
					if (data[i].total_amount != null) {
						// if(this.dataParams.tax_type === "vat"){
						let extra_helper = data[i].extra_helper;
						if (!extra_helper) {
							extra_helper = 0
						}
						let extra_drop = data[i].extra_drop;
						if (!extra_drop) {
							extra_drop = 0
						}
						let toll_fee = data[i].toll_fee;
						if (!toll_fee) {
							toll_fee = 0
						}
						let other_expenses = data[i].other_expenses;
						if (!other_expenses) {
							other_expenses = 0
						}
						// NON-VAT COMPUTATION
						data[i].overall_total = parseFloat((
							parseFloat(extra_helper) +
							parseFloat(extra_drop) +
							parseFloat(toll_fee) +
							parseFloat(other_expenses) +
							parseFloat(data[i].total_amount)
						));
						// VAT COMPUTATION
						if (this.dataParams.tax_type === "vat") {
							let vat = (
								(
									parseFloat(data[i].total_amount) +
									parseFloat(extra_drop) +
									parseFloat(toll_fee)
									// parseFloat(extra_helper) + 
									// parseFloat(other_expenses)
								) * 0.12
							).toFixed(2);
							data[i].overall_total += parseFloat(vat);
						}
						
						//Over all tOtAL computation of client rate,tax, other expenses, extra drop , toll fee and client rate
						total_amount += parseFloat(data[i].overall_total);

						//total amount in bookings list format
						data[i].overall_total = this.$options.filters.number_format(data[i].overall_total, 'currency', 2)
					}
				}
				else if(this.is_marvel()){
					if (data[i].total_amount != null) {
						// if(this.dataParams.tax_type === "vat"){
						let extra_helper = data[i].extra_helper;
						if (!extra_helper) {
							extra_helper = 0
						}
						let extra_drop = data[i].extra_drop;
						if (!extra_drop) {
							extra_drop = 0
						}
						let toll_fee = data[i].toll_fee;
						if (!toll_fee) {
							toll_fee = 0
						}
						let other_expenses = data[i].other_expenses;
						if (!other_expenses) {
							other_expenses = 0
						}
						// NON-VAT COMPUTATION
						data[i].overall_total = parseFloat((
							
							parseFloat(toll_fee) +
							parseFloat(data[i].total_amount)
						));
						// VAT COMPUTATION
						if (this.dataParams.tax_type === "vat") {
							let vat = (
								(
									parseFloat(data[i].total_amount) +
									parseFloat(toll_fee)
								) * 0.12
							).toFixed(2);
							data[i].overall_total += parseFloat(vat);
						}
						
						//Over all tOtAL computation of client rate,tax, other expenses, extra drop , toll fee and client rate
						total_amount += parseFloat(data[i].overall_total);

						//total amount in bookings list format
						data[i].overall_total = this.$options.filters.number_format(data[i].overall_total, 'currency', 2)
					}
				}
				else {
					if (data[i].total_amount != null) {
						// if(this.dataParams.tax_type === "vat"){
						let extra_helper = data[i].extra_helper;
						if (!extra_helper) {
							extra_helper = 0
						}
						let extra_drop = data[i].extra_drop;
						if (!extra_drop) {
							extra_drop = 0
						}
						let toll_fee = data[i].toll_fee;
						if (!toll_fee) {
							toll_fee = 0
						}
						let other_expenses = data[i].other_expenses;
						if (!other_expenses) {
							other_expenses = 0
						}
						// NON-VAT COMPUTATION
						data[i].overall_total = parseFloat((
							parseFloat(data[i].total_amount)
						));
						// VAT COMPUTATION
						if (this.dataParams.tax_type === "vat") {
							let vat = (
								(
									parseFloat(data[i].total_amount) 
									
								) * 0.12
							).toFixed(2);
							data[i].overall_total += parseFloat(vat);
						}
						
						//Over all tOtAL computation of client rate,tax, other expenses, extra drop , toll fee and client rate
						total_amount += parseFloat(data[i].overall_total);

						//total amount in bookings list format
						data[i].overall_total = this.$options.filters.number_format(data[i].overall_total, 'currency', 2)
					}
				}
			}

			return total_amount;
		},
		processBooking(data) {
			this.bookingList.data = data.data.map((value, index) => {
				value.overall_total = 0;
				value.booking_type_id_label = value.booking_type ?? '-';
				value.customer_id_label = value.customer_name ?? '-'
				value.origin_id_label = value.origin ?? '-'
				value.destination_id_label = value.destination ?? '-'
				value.depot_id_label = value.depot ?? '-';
				value.commodity_id_label = value.commodity_type ?? '-';
				value.delivery_date = value.delivery_date ?? null;
				if (value.delivery_date) {
					value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
				}
				else {
					value.delivery_date_label = "-"
				}
				if (value.total_amount) {
					value.total_amount = value.total_amount;
				}
				else {
					value.total_amount = "0.00"
				}
				if (value.client_rate) {
					value.client_rate = value.client_rate
				}

				else {
					value.client_rate = "0.00"
				}
				if (value.is_subcon == false || value.is_subcon == 0) {
					value.vehicle_type_id_label = value.company_name
				}
				else {

					value.vehicle_type_id_label = value.vendor_name

				}
				return value;
			});
			this.dataParams.total_amount = 0.00;
			// this.dataParams.clientrate_total = 0.00;
			// this.dataParams.sub_total = 0.00;
			// this.dataParams.tollfee_total = 0.00;
			// this.dataParams.extradrop_total = 0.00;
			// this.dataParams.other_payments = 0.00;
			for (var i = 0; i < this.bookingList.data.length; i++) {

				this.bookingList.data[i].toll_fees = this.bookingList.data[i].toll_fee ? this.bookingList.data[i].toll_fee : "0.00";
				this.bookingList.data[i].extra_drop = this.bookingList.data[i].extra_drop ? this.bookingList.data[i].extra_drop : "0.00";
				this.bookingList.data[i].extra_helper = this.bookingList.data[i].extra_helper ? this.bookingList.data[i].extra_helper : "0.00";
				this.bookingList.data[i].other_fees = this.bookingList.data[i].other_expenses ? this.bookingList.data[i].other_expenses : "0.00";
				
				if(this.is_roadwise()){
					if (this.bookingList.data[i].total_amount != null) {
						// if(this.dataParams.tax_type === "vat"){
						let extra_helper = this.bookingList.data[i].extra_helper;
						if (!extra_helper) {
							extra_helper = 0
						}
						let extra_drop = this.bookingList.data[i].extra_drop;
						if (!extra_drop) {
							extra_drop = 0
						}
						let toll_fee = this.bookingList.data[i].toll_fee;
						if (!toll_fee) {
							toll_fee = 0
						}
						let other_expenses = this.bookingList.data[i].other_expenses;
						if (!other_expenses) {
							other_expenses = 0
						}
						// NON-VAT COMPUTATION
						this.bookingList.data[i].overall_total = parseFloat((
							parseFloat(extra_helper) +
							parseFloat(extra_drop) +
							parseFloat(toll_fee) +
							parseFloat(other_expenses) +
							parseFloat(this.bookingList.data[i].total_amount)
						));
						// VAT COMPUTATION
						if (this.dataParams.tax_type === "vat") {
							let vat = (
								(
									parseFloat(this.bookingList.data[i].total_amount) +
									parseFloat(extra_drop) +
									parseFloat(toll_fee)
									// parseFloat(extra_helper) + 
									// parseFloat(other_expenses)
								) * 0.12
							).toFixed(2);
							this.bookingList.data[i].overall_total += parseFloat(vat);
						}
						
						//Over all tOtAL computation of client rate,tax, other expenses, extra drop , toll fee and client rate
						this.dataParams.total_amount += parseFloat(this.bookingList.data[i].overall_total);

						//total amount in bookings list format
						this.bookingList.data[i].overall_total = this.$options.filters.number_format(this.bookingList.data[i].overall_total, 'currency', 2)
					}
				}
				else if(this.is_marvel()){
					if (this.bookingList.data[i].total_amount != null) {
						// if(this.dataParams.tax_type === "vat"){
						let extra_helper = this.bookingList.data[i].extra_helper;
						if (!extra_helper) {
							extra_helper = 0
						}
						let extra_drop = this.bookingList.data[i].extra_drop;
						if (!extra_drop) {
							extra_drop = 0
						}
						let toll_fee = this.bookingList.data[i].toll_fee;
						if (!toll_fee) {
							toll_fee = 0
						}
						let other_expenses = this.bookingList.data[i].other_expenses;
						if (!other_expenses) {
							other_expenses = 0
						}
						// NON-VAT COMPUTATION
						this.bookingList.data[i].overall_total = parseFloat((
							
							parseFloat(toll_fee) +
							parseFloat(this.bookingList.data[i].total_amount)
						));
						// VAT COMPUTATION
						if (this.dataParams.tax_type === "vat") {
							let vat = (
								(
									parseFloat(this.bookingList.data[i].total_amount) +
									parseFloat(toll_fee)
								) * 0.12
							).toFixed(2);
							this.bookingList.data[i].overall_total += parseFloat(vat);
						}
						
						//Over all tOtAL computation of client rate,tax, other expenses, extra drop , toll fee and client rate
						this.dataParams.total_amount += parseFloat(this.bookingList.data[i].overall_total);

						//total amount in bookings list format
						this.bookingList.data[i].overall_total = this.$options.filters.number_format(this.bookingList.data[i].overall_total, 'currency', 2)
					}
				}
				else {
					if (this.bookingList.data[i].total_amount != null) {
						// if(this.dataParams.tax_type === "vat"){
						let extra_helper = this.bookingList.data[i].extra_helper;
						if (!extra_helper) {
							extra_helper = 0
						}
						let extra_drop = this.bookingList.data[i].extra_drop;
						if (!extra_drop) {
							extra_drop = 0
						}
						let toll_fee = this.bookingList.data[i].toll_fee;
						if (!toll_fee) {
							toll_fee = 0
						}
						let other_expenses = this.bookingList.data[i].other_expenses;
						if (!other_expenses) {
							other_expenses = 0
						}
						// NON-VAT COMPUTATION
						this.bookingList.data[i].overall_total = parseFloat((
							parseFloat(this.bookingList.data[i].total_amount)
						));
						// VAT COMPUTATION
						if (this.dataParams.tax_type === "vat") {
							let vat = (
								(
									parseFloat(this.bookingList.data[i].total_amount) 
									
								) * 0.12
							).toFixed(2);
							this.bookingList.data[i].overall_total += parseFloat(vat);
						}
						
						//Over all tOtAL computation of client rate,tax, other expenses, extra drop , toll fee and client rate
						this.dataParams.total_amount += parseFloat(this.bookingList.data[i].overall_total);

						//total amount in bookings list format
						this.bookingList.data[i].overall_total = this.$options.filters.number_format(this.bookingList.data[i].overall_total, 'currency', 2)
					}
				}
			}
			this.searching = false;
			this.displayedBooking = [...this.bookingList.data];
			this.displayedBooking.splice(((this.currentPage - 1) + 20), this.bookingList.data.length)

			console.log(this.displayedBooking, 'sd')
		},
		//CREATE BILLING BOOKINGS LIST
		getBookings(is_search = 0) {
			
			this.searching = true;
			var from = moment(this.dataParams.delivery_date_from).startOf('day').unix();
			var to = moment(this.dataParams.delivery_date_to).startOf('day').unix() + (86399);
			this.dataParams.total_amount = 0.00;
			var client_billing_id = null;
			if (this.$route.params.id != undefined) {
				client_billing_id = this.$route.params.id;
			}
			var origin_id = this.dataParams.origin_id;
			if (origin_id == null) {
				origin_id = ""
			}
			var customer_id = this.dataParams.customer_id;
			if (customer_id == null) {
				customer_id = ""
			}
			var commodity_id = this.dataParams.commodity_id;
			if (commodity_id == null) {
				commodity_id = ""
			}
			var depot_id = this.dataParams.depot_id;
			if (depot_id == null) {
				depot_id = ""
			}

			let params = {
				client_billing_id,
				status: 'completed',
				from,
				to,
				origin_id,
				depot_id: this.dataParams.depot_id,
				customer_id,
				commodity_id,
				is_search
			}

			axios.get(`${config.api_path}/client_billing/booking/list`, {
				params
			})
				.then(response => {
					this.disableSubmit = false;
					// this.bookingList = response.data;  
					// console.log(this.bookingList)
					this.processBooking(response.data)
				})
				.catch(e => {
					this.searching = false;
					this.disableSubmit = false;
				})
		},
		getBookingsOld() {
			this.searching = true;
			var from = moment(this.dataParams.delivery_date_from).startOf('day').unix();
			var to = moment(this.dataParams.delivery_date_to).startOf('day').unix() + (86399);
			this.dataParams.total_amount = 0.00;
			var client_billing_id = null;
			if (this.$route.params.id != undefined) {
				client_billing_id = this.$route.params.id;
			}
			var origin_id = this.dataParams.origin_id;
			if (origin_id == null) {
				origin_id = ""
			}
			var customer_id = this.dataParams.customer_id;
			if (customer_id == null) {
				customer_id = ""
			}
			var commodity_id = this.dataParams.commodity_id;
			if (commodity_id == null) {
				commodity_id = ""
			}
			var depot_id = this.dataParams.depot_id;
			if (depot_id == null) {
				depot_id = ""
			}
			axios.get(config.api_path + '/client_billing/booking/list?client_billing_id=' + client_billing_id + '&status=completed&from=' + from + '&to=' + to + '&origin_id=' + origin_id + '&customer_id=' + customer_id + '&commodity_id=' + commodity_id + '&depot_id=' + this.dataParams.depot_id + '&page=' + 1 + '&limit=10000000')
				.then(response => {

					this.disableSubmit = false;
					this.bookingList = response.data;

					console.log(this.bookingList)

					this.bookingList.data = this.bookingList.data.map((value, index) => {
						value.overall_total = 0;
						if (value.booking_type_id) {
							value.booking_type_id_label = value.booking_type?.setting_name
						}
						else {
							value.booking_type_id_label = "N/A"
						}
						if (value.customer_id) {
							value.customer_id_label = value.customer?.customer_name
						}
						else {
							value.customer_id_label = "N/A"
						}
						if (value.origin?.setting_name) {
							value.origin_id_label = value.origin?.setting_name
						}
						else {
							value.origin_id_label = "N/A"
						}
						if (value.destination?.setting_name) {
							value.destination_id_label = value.destination?.setting_name
						}
						else {
							value.destination_id_label = "N/A"
						}
						if (value.depot?.setting_name) {
							value.depot_id_label = value.depot?.setting_name
						}
						else {
							value.destination_id_label = "N/A"
						}
						if (value.commodity_id) {
							value.commodity_id_label = value.commodity?.setting_name
						}
						else {
							value.commodity_id_label = "N/A"
						}
						if (value.asset_type == 'vehicle') {
							value.asset_no = value.vehicle ? value.vehicle.plate_no : "N/A"
						}
						if (value.asset_type == 'equipment') {
							value.asset_no = value.equipment ? value.equipment.equipment_prep_no : "N/A"
						}
						if (value.asset_type == 'furnishing') {
							value.asset_no = value.furnishing ? value.furnishing.asset_no : "N/A"
						}
						value.asset_type = value.asset_type.charAt(0).toUpperCase() + value.asset_type.slice(1)
						if (value.delivery_date) {
							value.delivery_date = value.delivery_date
						}
						else {
							value.delivery_date = "N/A"
						}
						if (value.client_ref_no == "" || value.client_ref_no == null) {
							value.client_ref_no_label = "N/A";
						}
						if (value.maker_model_id) {
							value.maker_model_id_label = value.maker_model?.setting_name
						}
						if (value.with_gps) {
							value.with_gps_label = "Yes";
						}
						else {
							value.with_gps_label = "No";
						}
						if (value.delivery_date) {
							value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
						}
						else {
							value.delivery_date_label = "N/A"
						}
						if (value.total_amount) {
							value.total_amount = value.total_amount;
						}
						else {
							value.total_amount = "0.00"
						}
						if (value.client_rate) {
							value.client_rate = value.client_rate
						}

						else {
							value.client_rate = "0.00"
						}
						if (value.is_subcon == false || value.is_subcon == 0) {
							value.vehicle_type_id_label = value.company.company_name
						}
						else {
							if (value.vendor) {
								value.vehicle_type_id_label = value.vendor.vendor_name
							}
							else {
								value.vehicle_type_id_label = "N/A"
							}
						}
						return value;
					});
					this.dataParams.total_amount = 0.00;
					this.dataParams.clientrate_total = 0.00;
					this.dataParams.sub_total = 0.00;
					this.dataParams.tollfee_total = 0.00;
					this.dataParams.extradrop_total = 0.00;
					this.dataParams.other_payments = 0.00;
					for (var i = 0; i < this.bookingList.data.length; i++) {

						this.bookingList.data[i].toll_fees = this.bookingList.data[i].billable?.toll_fee ? this.bookingList.data[i].billable?.toll_fee : "N/A";
						this.bookingList.data[i].extra_drop = this.bookingList.data[i].billable?.extra_drop ? this.bookingList.data[i].billable?.extra_drop : "N/A";
						this.bookingList.data[i].extra_helper = this.bookingList.data[i].billable?.extra_helper ? this.bookingList.data[i].billable?.extra_helper : "N/A";
						this.bookingList.data[i].other_fees = this.bookingList.data[i].billable?.other_expenses ? this.bookingList.data[i].billable?.other_expenses : "N/A";
						if (this.bookingList.data[i].total_amount != null) {
							// if(this.dataParams.tax_type === "vat"){
							let extra_helper = this.bookingList.data[i].billable?.extra_helper;
							if (!extra_helper) {
								extra_helper = 0
							}
							let extra_drop = this.bookingList.data[i].billable?.extra_drop;
							if (!extra_drop) {
								extra_drop = 0
							}
							let toll_fee = this.bookingList.data[i].billable?.toll_fee;
							if (!toll_fee) {
								toll_fee = 0
							}
							let other_expenses = this.bookingList.data[i].billable?.other_expenses;
							if (!other_expenses) {
								other_expenses = 0
							}
							this.bookingList.data[i].overall_total = parseFloat((
								parseFloat(extra_helper) +
								parseFloat(extra_drop) +
								parseFloat(toll_fee) +
								parseFloat(other_expenses) +
								parseFloat(this.bookingList.data[i].total_amount)
							));
							// VAT COMPUTATION
							if (this.dataParams.tax_type === "vat") {
								let vat = (
									(
										parseFloat(this.bookingList.data[i].total_amount) +
										parseFloat(extra_drop) +
										parseFloat(toll_fee)
										// parseFloat(extra_helper) + 
										// parseFloat(other_expenses)
									) * 0.12
								).toFixed(2);
								this.bookingList.data[i].overall_total += parseFloat(vat);
							}
							//Total Computation (Client rate+extra drop+tollfee)
							this.dataParams.sub_total +=
								(
									parseFloat(toll_fee) +
									parseFloat(extra_drop) +
									parseFloat(this.bookingList.data[i].total_amount));
							//client rate total
							this.dataParams.clientrate_total +=
								(
									parseFloat(this.bookingList.data[i].total_amount));
							//toll fee total
							this.dataParams.tollfee_total +=
								(
									parseFloat(toll_fee));

							//extra drop total
							this.dataParams.extradrop_total +=
								(
									parseFloat(extra_drop));

							//Computation of other expenses (other fees+extra_helper) 
							this.dataParams.other_payments +=
								(parseFloat(extra_helper) +
									parseFloat(other_expenses))
							// parseFloat(extra_drop));
							this.dataParams.total_amount += parseFloat(this.bookingList.data[i].overall_total);

							//total amount in bookings list format
							this.bookingList.data[i].overall_total = this.$options.filters.number_format(this.bookingList.data[i].overall_total, 'currency', 2)

						}
					}
					this.searching = false;
					this.displayedBooking = [...this.bookingList.data];
					// this.displayedBooking.splice(((this.currentPage-1) + 20), this.bookingList.data.length)
					// console.log(this.displayedBooking, 'sd')
				})
				.catch(e => {
					this.searching = false;
					this.disableSubmit = false;
				})
		},
		//UPDATE BILLING BOOKINGS LIST
		getData() {
			this.$showLoading(true)
			axios.get(config.api_path + "/client_billing/v2/" + this.$route.params.id)
				.then(response => {
					this.$showLoading(false)
					this.dataParams = response.data.data;
					this.dataParams.total_amount = 0.00;
					this.dataParams.delivery_date_from = moment(new Date(this.dataParams.delivery_date_from * 1000)).format('MM-DD-YYYY')
					this.dataParams.delivery_date_to = moment(new Date(this.dataParams.delivery_date_to * 1000)).format('MM-DD-YYYY')

					this.getBookings()
					// this.bookingList.data = this.dataParams.booking.map((value, index)=>{ 
					// 	value.toll_fees = value.billable?.toll_fee ? value.billable?.toll_fee : "N/A";
					// 	value.extra_drop = value.billable?.extra_drop ? value.billable?.extra_drop : "N/A";
					// 	value.extra_helper = value.billable?.extra_helper ? value.billable?.extra_helper : "N/A";
					// 	value.other_fees = value.billable?.other_expenses ? value.billable?.other_expenses : "N/A";
					// 	if(value.booking_type_id){
					// 		value.booking_type_id_label = value.booking_type?.setting_name
					// 	}
					// 	else{
					// 		value.booking_type_id_label = "N/A"
					// 	}
					// 	if(value.customer_id){
					// 		value.customer_id_label = value.customer?.customer_name
					// 	}
					// 	else{
					// 		value.customer_id_label = "N/A"
					// 	}
					// 	if(value.origin_id){
					// 		value.origin_id_label = value.origin?.setting_name
					// 	}
					// 	else{
					// 		value.origin_id_label = "N/A"
					// 	}
					// 	if(value.depot_id){
					// 		value.depot_id_label = value.depot?.setting_name
					// 	}
					// 	else{
					// 		value.origin_id_label = "N/A"
					// 	}
					// 	if(value.destination?.setting_name){
					// 		value.destination_id_label = value.destination?.setting_name
					// 	}
					// 	else{
					// 		value.destination_id_label = "N/A"
					// 	}
					// 	if(value.commodity_id){
					// 		value.commodity_id_label = value.commodity?.setting_name
					// 	}
					// 	else{
					// 		value.commodity_id_label = "N/A"
					// 	} 
					// 	if(value.asset_type == 'vehicle'){
					// 		value.asset_no = value.vehicle ? value.vehicle.plate_no : "N/A"
					// 	}
					// 	if(value.asset_type == 'equipment'){
					// 		value.asset_no = value.equipment ? value.equipment.equipment_prep_no : "N/A"
					// 	}
					// 	if(value.asset_type == 'furnishing'){
					// 		value.asset_no = value.furnishing ? value.furnishing.asset_no : "N/A"
					// 	}
					// 	value.asset_type = value.asset_type.charAt(0).toUpperCase() + value.asset_type.slice(1)
					// 	if(value.delivery_date){
					// 		value.delivery_date = value.delivery_date
					// 	}
					// 	else{
					// 		value.delivery_date = "N/A"
					// 	}
					// 	if(value.client_ref_no == "" || value.client_ref_no == null){
					// 		value.client_ref_no_label = "N/A";
					// 	}
					// 	if(value.maker_model_id){
					// 		value.maker_model_id_label = value.maker_model?.setting_name
					// 	}
					// 	if(value.with_gps){
					// 		value.with_gps_label = "Yes";
					// 	}
					// 	else{
					// 		value.with_gps_label = "No";
					// 	}
					// 	if(value.delivery_date){
					// 		value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
					// 	}
					// 	else{
					// 		value.delivery_date_label = "N/A"
					// 	}
					// 	if(value.total_amount){
					// 		value.total_amount = value.total_amount;
					// 	}
					// 	else{
					// 		value.total_amount = "0.00"
					// 	}
					// 	if(value.client_rate){
					// 		value.client_rate= value.client_rate
					// 	}

					// 	else{
					// 		value.client_rate = "0.00" 
					// 	}
					// 	if(value.is_subcon == false || value.is_subcon == 0){
					// 		value.vehicle_type_id_label = value.company.company_name
					// 	}
					// 	else{
					// 		if(value.vendor){
					// 			value.vehicle_type_id_label = value.vendor.vendor_name
					// 		}
					// 		else{
					// 			value.vehicle_type_id_label = "N/A"
					// 		}
					// 	}
					// 	value.overall_total = 0;
					// 	return value;
					// }); 
					// this.dataParams.total_amount = 0.00;
					// // this.dataParams.clientrate_total = 0.00;
					// // this.dataParams.sub_total = 0.00;
					// // this.dataParams.tollfee_total =0.00;
					// // this.dataParams.extradrop_total =0.00;
					// // this.dataParams.other_payments = 0.00;
					// for(var i = 0; i < this.dataParams.booking.length; i++){
					// 	this.bookingList.data[i].toll_fees = this.bookingList.data[i].toll_fee ? this.bookingList.data[i].toll_fee : "N/A";
					// 	this.bookingList.data[i].extra_drop = this.bookingList.data[i].extra_drop ? this.bookingList.data[i].extra_drop : "N/A";
					// 	this.bookingList.data[i].extra_helper = this.bookingList.data[i].extra_helper ? this.bookingList.data[i].extra_helper : "N/A";
					// 	this.bookingList.data[i].other_fees = this.bookingList.data[i].other_expenses ? this.bookingList.data[i].other_expenses : "N/A";
					// 	if(this.bookingList.data[i].total_amount != null){
					// 		let extra_helper = this.bookingList.data[i].billable?.extra_helper;
					// 		if(!extra_helper){
					// 			extra_helper = 0
					// 		}
					// 		let extra_drop = this.bookingList.data[i].billable?.extra_drop;
					// 		if(!extra_drop){
					// 			extra_drop = 0
					// 		}
					// 		let toll_fee = this.bookingList.data[i].billable?.toll_fee;
					// 		if(!toll_fee){
					// 			toll_fee = 0
					// 		}
					// 		let other_expenses = this.bookingList.data[i].billable?.other_expenses;
					// 		if(!other_expenses){
					// 			other_expenses = 0
					// 		}
					// 		this.bookingList.data[i].overall_total = parseFloat((
					// 				parseFloat(extra_helper) + 
					// 				parseFloat(extra_drop) + 
					// 				parseFloat(toll_fee) + 
					// 				parseFloat(other_expenses) + 
					// 				parseFloat(this.bookingList.data[i].total_amount)
					// 			));
					// 		if(this.dataParams.tax_type === "vat"){
					// 			let vat = (
					// 				(
					// 					parseFloat(this.bookingList.data[i].total_amount) + 
					// 					parseFloat(extra_drop) + 
					// 					parseFloat(toll_fee) 
					// 					// parseFloat(extra_helper) + 
					// 					// parseFloat(other_expenses)
					// 				) * 0.12
					// 			).toFixed(2);
					// 			this.bookingList.data[i].overall_total += parseFloat(vat);
					// 		}



					// 		this.dataParams.total_amount += parseFloat(this.bookingList.data[i].overall_total);

					// 		this.bookingList.data[i].overall_total = this.$options.filters.number_format(this.bookingList.data[i].overall_total,'currency',2)
					// 		// alert('asd')

					// 	} 
					// }
					// this.displayedBooking = [...this.dataParams.booking];  
					// this.displayedBooking.splice(((this.currentPage-1) + 20), this.dataParams.booking.length)
				})
				.catch(err => {
					this.$showLoading(false)
				})
		},
		clearSearch() {
			this.dataParams = {
				origin_id: "",
				commodity_id: "",
				delivery_date_from: "",
				delivery_date_to: "",
				customer_id: "",
				depot_id: ''
			}
			this.displayedBooking = [];
		},
		changeTerm(){
			// alert('test: '+this.dataParams.term);
			let due_date = this.$options.filters.termsCompute(this.dataParams.term)
			this.dataParams.term_due_date = due_date;
		},
		changeTermDueDate(){
			this.dataParams.term = 'custom';
		}
	},
}
</script>
